import { useEffect, useState, useContext } from "react";
import { Box, Menu, MenuItem } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import { ClientConfigContext } from "../../context/clientConfigContext";
import { getUserStatus, resendEmailVerification } from "../../lib/usersBEClient";
import { getMemberChargesByCompany, getMemberChargesByManyCompanies } from "../../lib/governanceClient";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackBarContext } from "../../context/snackBarContext";
interface UsersTableProps {
   group: boolean;
}

export const CompanieUsersTable = (props: UsersTableProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { clientInfo, group } = useContext(ClientConfigContext);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [companyUsers, setCompanyUsers] = useState([]);
   const [open, setOpen] = useState(null);
   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
      setOpen(null);
   };
   const [isLoading, setIsLoading] = useState(true);
   const GCRoles = ["N1", "N2", "N3", "N4", "N5", "N6", "N7", "N8", "N9"];

   const headCells: HeadCell[] = [
      { field: "username", headerName: "Nombre", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "status", headerName: "Estatus", type: "disabled" },
      {
         field: "actions",
         headerName: "Acciones",
         type: "button",
         width: 50,
         icon: <MoreVertIcon fontSize="small" />,
         onClick: async (e, row) => {
            handleClick(e);
            setOpen(row);
         },
      },
   ];

   const handleValidateEmail = async () => {
      handleClose();
      const response = await resendEmailVerification(open.email);
      if (response?.status === 200) showSnackBar("Se ha enviado el correo de verificación", false);
      else showSnackBar("Error al validar el correo, intentelo de nuevo más tarde", true);
   };

   function getRoles(roles: any[]) {
      const companyRoles = props.group
         ? roles.filter((r) => r.group === group._id || group.companies.map((c) => c._id).includes(r.company))
         : roles.filter((r) => r.company === clientInfo._id);
      const rolesArray = companyRoles.flatMap((role) => role.roles.map((r) => r.name));
      return Array.from(new Set(rolesArray));
   }

   const fetchCompanyUsers = async () => {
      setIsLoading(true);
      try {
         const checkContext = props.group ? group.users : clientInfo.company_details.users;
         const userStatusMap = new Map(
            (await getUserStatus(checkContext.map((user) => user._id))).map((u) => [u._id, u.disabled])
         );
         const usersData = checkContext.map((user) => ({
            _id: user._id,
            uid: user.uid,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
            roles: getRoles(user.role).length > 0 ? getRoles(user.role) : ["sin datos"],
            status: userStatusMap.get(user._id) ?? false,
         }));

         const gcUserIds = usersData
            .filter((user) => user.roles.some((r) => GCRoles.includes(r)))
            .map((user) => user._id);

         if (gcUserIds.length > 0) {
            let GcChargesResponse;
            if (props.group) {
               const companyIds = group.companies.map((company) => company._id);
               const chargesResponses = await getMemberChargesByManyCompanies(gcUserIds, companyIds);
               GcChargesResponse = chargesResponses;
            } else {
               GcChargesResponse = await getMemberChargesByCompany(gcUserIds, clientInfo._id);
            }
            for (const user of usersData) {
               const index = GcChargesResponse.findIndex((c) => c.user === user._id);
               if (index >= 0) {
                  user.roles = user.roles.concat(GcChargesResponse[index].charges);
                  user.roles = user.roles.filter((r) => !GCRoles.includes(r));
               }
            }
         }
         setIsLoading(false);
         setCompanyUsers(usersData);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchCompanyUsers();
      // eslint-disable-next-line
   }, []);

   return (
      <Box sx={{ width: "100%" }}>
         <TableComponent
            defaultColumnToOrder="status"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={headCells}
            rows={companyUsers}
            onClick={() => {}}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados"
         />
         <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={handleValidateEmail}>Reestablecer correo</MenuItem>
         </Menu>
      </Box>
   );
};
