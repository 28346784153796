import { Box, Stack } from "@mui/material";
import GovernanceCoordination from "../../Clients/SubComponents/ServiceInfoForm/Governance/subcomponents/CompanyCoordination";
import { ConsultiveCompaniesTable } from "./ConsultiveGroup/ConsultiveCompaniesTable";
import { ConsultiveGroupUsers } from "./ConsultiveGroup/ConsultiveGroupUsers";
import { GoverningBodiesComponent } from "../../Clients/SubComponents/ServiceInfoForm/Governance/GoverningBodiesComponent";

const ConsultiveGroup = () => {
   return (
      <Stack spacing={2}>
         <Box
            sx={{
               px: 3,
               py: 2,
               borderRadius: 1,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <GoverningBodiesComponent group />
         </Box>
         <ConsultiveGroupUsers />
         <Box
            sx={{
               px: 3,
               py: 2,
               borderRadius: 1,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <GovernanceCoordination group />
         </Box>
         <ConsultiveCompaniesTable />
      </Stack>
   );
};

export default ConsultiveGroup;
